  // This file is automatically compiled by Webpack, along with any other files
  // present in this directory. You're encouraged to place your actual application logic in
  // a relevant structure within app/javascript and only use these pack files to reference
  // that code so it'll be compiled.

  //= require algolia/v3/algoliasearch.min
  //= require algolia/typeahead.jquery
  //= require hogan

  require("@rails/ujs").start()
  require("turbolinks").start()
  require("@rails/activestorage").start()
  require("channels")
  require('jquery')
  //const flatpickr = require("flatpickr");
  // Uncommenti to copy all static images under ../images to the output folder and reference
  // them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
  // or the `imagePath` JavaScript helper below.
  //
  // const images = require.context('../images', true)
  // const imagePath = (name) => images(name, true)
  import "@fortawesome/fontawesome-free/js/all";
  import 'bootstrap';
  import "bootstrap-select"
  import "../stylesheets/application";
  import "../stylesheets/login";
  import "../stylesheets/global";
  import flatpickr from 'flatpickr';
  
  import { Spanish } from "flatpickr/dist/l10n/es.js";
  import $ from 'jquery';
  // import 'bootstrap-datepicker';


  // $(document).on('turbolinks:load', function() {
  // alert("NEXT");

  // });

  $(document).ready(function() {
      $("#show_hide_password a").on('click', function(event) {
          event.preventDefault();
          if($('#show_hide_password input').attr("type") == "text"){
              $('#show_hide_password input').attr('type', 'password');
              $('#show_hide_password svg').addClass( "fa-eye-slash" );
              $('#show_hide_password svg').removeClass( "fa-eye" );
          }else if($('#show_hide_password input').attr("type") == "password"){
              $('#show_hide_password input').attr('type', 'text');
              $('#show_hide_password svg').removeClass( "fa-eye-slash" );
              $('#show_hide_password svg').addClass( "fa-eye" );
          }
      });
   
  });


    // url = "/home/state_options?parent_state=#{cat}"
    // $.ajax
    //   type: 'GET'
    //   url: url
    //   success: (data) ->
    //     select_wrapper.attr('disabled', false)
    //     select_wrapper.html(data)
    //   error: (error) ->
    //     alert 'Por favor intente nuevamente: ' + error



  $(document).on('turbolinks:load', function() {
      //these methods are called it on load
      components_ready();
      expedient_ready(); 
      autoasignar_masivamente();
      new_subcategories();
      $("#expedient_main_category_id").change(expedient_ready);
      $("#expedient_es_cliente_banco").change(expedient_ready);
      $("#expedient_tipo_persona").change(expedient_ready);
      $("#expedient_busq_categoria").change(search_ready);

      $("#expedient_creado_por").selectpicker(
        {     
          liveSearch: true
        }
      );

      $("#expedient_revisado_por").selectpicker(
        {                
          liveSearch: true
        }
      );
        $('.selectpicker').selectpicker('refresh');

  });

  function components_ready() {


    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();
    
    $('.date').flatpickr(
      {      
        dateFormat: "d-m-Y",
        "locale": Spanish,
      });

    let feriado = document.getElementById("holiday_date")

    if (feriado){

      var f = $('#holiday_date').val().replace(/-/g, '\/').toString()
      console.log(new Date(f));

      $('.d').flatpickr(
        {
          dateFormat: "d-m-Y",
          "locale": Spanish,
          defaultDate: new Date(f),
          
          
        });

    }
   
    
    
    $(".datepicker").flatpickr(
      {
          altInput: true,
          altFormat: "F j, Y",
          "locale": Spanish,
      }
    );
    
   
  };

  function autoasignar_masivamente() {
    $(".expedient-checkbox").change(function() {
      var selectedIds = $(".expedient-checkbox:checked").map(function() {
        return $(this).val();
      }).get();
      $("#selected_ids_input").val(selectedIds.join(","));
      console.log(selectedIds);
    });
  };


  function expedient_ready() {
    var main_category = $("#expedient_main_category_id option:selected" ).text() 
    var cond_sop_recl = main_category == "Soporte de Reclamos"
    var cond_prev = main_category == "Prevención"

    if (main_category != "Persona" && main_category != "Empleado" && main_category != "Proveedores" && $("#expedient_main_category_id option:selected" ).val() != '' && !cond_sop_recl && !cond_prev){
      $('.tiene_padre').show();
      if ($("#expedient_tipo_persona option:selected" ).text() == "Persona Natural"){
        $('.ayuda_natural').show();
        $('.ayuda_juridica').hide();
      }else if ($("#expedient_tipo_persona option:selected" ).text() == "Persona Jurídica"){
        $('.ayuda_juridica').show();
        $('.ayuda_natural').hide();

      }else{
        $('.ayuda_natural').hide();
        $('.ayuda_juridica').hide();
      }
    }else {
      $('.tiene_padre').hide();
      $('#expedient_father_expedient_identification').val(''); 
    }


    if (main_category == "Persona"){
      $('.es_cliente_banco').show();
      $('.email_cliente').show();
      
    }else {
      $('.es_cliente_banco').hide();
      $('.email_cliente').hide();
      
      
    }


    if (cond_sop_recl || cond_prev){
      $('.tipo_persona').hide();
      
    }else {
      $('.tipo_persona').show();
      
    }

    $('.identificador_unico').show();
    $('.ayuda').show();
    $('.tdc_franquicia').hide();
    $('.tipo_gestion').hide();
    $('.fecha').hide();
       

    if (main_category == "Créditos" || main_category == "Tarjetas" || main_category == "Reclamos") {
      $('.tiene_subtipo').show();
    } else {
      $('.tiene_subtipo').hide();
      $('#expedient_subtipo').val('');      
    }

    if ((main_category == "Reclamos" || main_category == "Defensor del Cliente") && (role == "Digitalizador" || role == "Gerente Operativo")){
      $('.identificador_unico').hide();
      $('.identificador').hide();
    }else {
      $('.identificador').show();
    }

    if(main_category == "Reclamos" &&  (role != "Digitalizador" && role != "Gerente Operativo")) {
      $('.identificador_final').show();
    }else{
      $('.identificador_final').hide();
    }
    
    if(main_category == "Defensor del Cliente") {
      $('.identificador_reclamos').show();
    }else{
      $('.identificador_reclamos').hide();
    } 

    if (main_category == "POS"){
      $('.identificador_unico').hide();
      $('.origen').show();
      $('.sucursal').show();
      $('.cantidad_puntos').show()
      $('.fecha').show();
    }else {
      $('.origen').hide();
      $('.sucursal').hide();
      $('.cantidad_puntos').hide()
    }

    if (main_category == "Empleado") {
    
      $("#expedient_tipo_persona option[value='Juridica']").remove();
    
    }else if ($("#expedient_tipo_persona option[value='Juridica']").length == 0){
    
      $("#expedient_tipo_persona").append('<option value="Juridica">Persona Jurídica</option>');
    }

    if (main_category == "Pago de Nómina") {
    
      $("#expedient_tipo_persona option[value='Natural']").remove();
    
    }else if ($("#expedient_tipo_persona option[value='Natural']").length == 0){
    
      $("#expedient_tipo_persona").append('<option value="Natural">Persona Natural</option>');
    }


    

    if (main_category == "Créditos") {
      $('.identificador_unico').hide();
      $('.tipo_producto').show();
      $('.monto_operacion').show();  
      $('.fecha').show();    
      
      $("#expedient_subtipo option[value='Riesgo de Crédito']").remove();
      $("#expedient_subtipo option[value='Agencias']").remove();
      $("#expedient_subtipo option[value='Medios de Pago']").remove();

      $("#expedient_subtipo option[value='General']").remove();
      $("#expedient_subtipo option[value='Tarjeta de Débito']").remove();
      $("#expedient_subtipo option[value='Tarjeta de Crédito']").remove();
      $("#expedient_subtipo option[value='Reclamos POS']").remove();
      $("#expedient_subtipo option[value='Operaciones Electrónicas']").remove();
      $("#expedient_subtipo option[value='Queja']").remove();

      fill_select_tipo_producto();

      // if ($("#expedient_tipo_persona option:selected" ).text() == "Persona Natural"){
      //   // $("#expedient_tipo_producto option[value='CPU']").remove();
      //   $("#expedient_tipo_producto option[value='MNF']").remove();
      //   $("#expedient_tipo_producto option[value='AGR']").remove();

      //   if ($("#expedient_tipo_producto option[value='EMPR']").length == 0){
      //     $("#expedient_tipo_producto").append('<option value="EMPR">Crédito Emprendedor</option>');
      //   }
      //   if ($("#expedient_tipo_producto option[value='MUJ']").length == 0){
      //     $("#expedient_tipo_producto").append('<option value="MUJ">Credimujer</option>');
      //   }
      //   if ($("#expedient_tipo_producto option[value='MOTO']").length == 0){
      //     $("#expedient_tipo_producto").append('<option value="MOTO">Credimoto</option>');
      //   }

      // }else if ($("#expedient_tipo_persona option:selected" ).text() == "Persona Jurídica"){

  
      //   $("#expedient_tipo_producto option[value='EMPR']").remove();
        

      //   if ($("#expedient_tipo_producto option[value='AGR']").length == 0) {
      //     $("#expedient_tipo_producto").append('<option value="AGR">Agrícola (AGR)</option>');
      //   }

      //   // if ($("#expedient_tipo_producto option[value='CPU']").length == 0) {
      //   //   $("#expedient_tipo_producto").append('<option value="CPU">Cartera Productiva Única (CPU)</option>');
      //   // }
        
      //   if ($("#expedient_tipo_producto option[value='MNF']").length == 0){
      //     $("#expedient_tipo_producto").append('<option value="MNF">Manufactura (MNF)</option>');
      //   }
        
      //   //$("#expedient_tipo_producto").append('<option value="COM">Comercial (COM)</option>');
      //   //$("#expedient_tipo_producto").append('<option value="MCR">Microcrédito (MCR)</option>');

      //   $("#expedient_tipo_producto option[value='MUJ']").remove();
      //   $("#expedient_tipo_producto option[value='MOTO']").remove();
        
      // }


      if ($("#expedient_subtipo option[value='Con Garantía']").length == 0){
        $("#expedient_subtipo").append('<option value="Con Garantía">Con Garantía</option>');
      }
      if ($("#expedient_subtipo option[value='Sin Garantía']").length == 0){
        $("#expedient_subtipo").append('<option value="Sin Garantía">Sin Garantía</option>');
      }


    }else {
      $('.tipo_producto').hide();
      $('.monto_operacion').hide();
      $('#expedient_monto_operacion').val('');  
    }
    
    if (main_category == "Tarjetas") {
      $('.identificador_unico').hide();
      $('.tdc_franquicia').show();
      $('.tipo_gestion').show();
      $('.fecha').show();
      
      $("#expedient_subtipo option[value='Con Garantía']").remove();
      $("#expedient_subtipo option[value='Sin Garantía']").remove();

      $("#expedient_subtipo option[value='General']").remove();
      $("#expedient_subtipo option[value='Tarjeta de Débito']").remove();
      $("#expedient_subtipo option[value='Tarjeta de Crédito']").remove();
      $("#expedient_subtipo option[value='Reclamos POS']").remove();
      $("#expedient_subtipo option[value='Operaciones Electrónicas']").remove();
      $("#expedient_subtipo option[value='Queja']").remove();

      if ($("#expedient_subtipo option[value='Riesgo de Crédito']").length == 0){
        $("#expedient_subtipo").append('<option value="Riesgo de Crédito">Riesgo de Crédito</option>');
      }

      if ($("#expedient_subtipo option[value='Agencias']").length == 0){
        $("#expedient_subtipo").append('<option value="Agencias">Agencias</option>');
      }

      if ($("#expedient_subtipo option[value='Medios de Pago']").length == 0){
        $("#expedient_subtipo").append('<option value="Medios de Pago">Medios de Pago</option>');
      }
    }



    if (main_category == "Reclamos") {


      $("#expedient_subtipo option[value='Riesgo de Crédito']").remove();
      $("#expedient_subtipo option[value='Agencias']").remove();
      $("#expedient_subtipo option[value='Medios de Pago']").remove();

      $("#expedient_subtipo option[value='Con Garantía']").remove();
      $("#expedient_subtipo option[value='Sin Garantía']").remove();



      if ($("#expedient_subtipo option[value='General']").length == 0){
        $("#expedient_subtipo").append('<option value="General">General</option>');
      }

      if ($("#expedient_subtipo option[value='Tarjeta de Débito']").length == 0){
        $("#expedient_subtipo").append('<option value="Tarjeta de Débito">Tarjeta de Débito</option>');
      }

      if ($("#expedient_subtipo option[value='Tarjeta de Crédito']").length == 0){
        $("#expedient_subtipo").append('<option value="Tarjeta de Crédito">Tarjeta de Crédito</option>');
      }

      if ($("#expedient_subtipo option[value='Reclamos POS']").length == 0){
        $("#expedient_subtipo").append('<option value="Reclamos POS">Reclamos POS</option>');
      }

      if ($("#expedient_subtipo option[value='Operaciones Electrónicas']").length == 0){
        $("#expedient_subtipo").append('<option value="Operaciones Electrónicas">Operaciones Electrónicas</option>');
      }

      if ($("#expedient_subtipo option[value='Queja']").length == 0){
        $("#expedient_subtipo").append('<option value="Queja">Queja</option>');
      }

    }



    if ($("#expedient_identification_related").text() != " "){
      $('.mensaje_related').show();
    }else{
      $('.mensaje_related').hide();
    }




  }

  function fill_select_tipo_producto(){
    var tipoPersonaSelect = document.getElementById('tipo_persona_select');
    var tipoProductoSelect = document.getElementById('tipo_producto_select');

    tipoPersonaSelect.addEventListener('change', function() {
      var tipoPersona = tipoPersonaSelect.value;
      if (tipoPersona) {
        fetch(`/expedients/product_types?tipo_persona=${tipoPersona}`)
          .then(response => response.json())
          .then(data => {
            tipoProductoSelect.innerHTML = '<option value="">Seleccione</option>';
            data.forEach(function(productType) {
              var option = document.createElement('option');
              option.value = productType[1];
              option.textContent = productType[0];
              tipoProductoSelect.appendChild(option);
            });
          });
      } else {
        tipoProductoSelect.innerHTML = '<option value="">Seleccione</option>';
      }
    });
  }

  function search_ready(){
    $('#expedient_busq_subcategoria').val('');
    var select_wrapper = $('#expedient_busq_subcategoria');
    var category = $(this).val();
    var url = "/home/subcategory_options?subcategory="+category
    $.ajax({
      type: 'GET',
      url: url,
      success: function(data) {
        select_wrapper.attr('disabled', false);
        return select_wrapper.html(data);
      },
      error: function(error) {
        return alert('Por favor intente nuevamente: ' + error);
      }
    });


  }

  function new_subcategories() {
    var mainCategoryElement = document.getElementById('main_category_id');
    
    if (mainCategoryElement) {
      mainCategoryElement.addEventListener('change', function() {
        var mainCategoryId = this.value;
        var mainCategoryName = this.selectedOptions[0].textContent;
        console.log("ID:", mainCategoryId);
        console.log("Name:", mainCategoryName);
        var subtipoRow = document.getElementById('subtipo');
        var tipoPersonaSelect = document.getElementById('tipo_persona_select');
  
        // Update subtipo options
        if (mainCategoryName === "Reclamos" || mainCategoryName === "Tarjetas" || mainCategoryName === "Créditos") {
          fetch(`/subcategories/load_subtypes?main_category_id=${mainCategoryId}`, {
            headers: {
              'Accept': 'application/json',
            }
          })
          .then(response => response.json())
          .then(data => {
            var subtipoSelect = document.getElementById('subtipo_select');
            subtipoSelect.innerHTML = '<option value="">Seleccione un subtipo</option>';
            data.forEach(function(subtipo) {
              var option = document.createElement('option');
              option.value = subtipo;
              option.textContent = subtipo;
              subtipoSelect.appendChild(option);
            });
            subtipoRow.classList.remove('d-none');
          });
        } else {
          subtipoRow.classList.add('d-none');
          document.getElementById('subtipo_select').innerHTML = '<option value="">Seleccione un subtipo</option>';
        }
  
        // Update tipo_persona options
        if (mainCategoryName === "Créditos" || mainCategoryName === "Defensor del Cliente") {
          if (!Array.from(tipoPersonaSelect.options).some(option => option.value === "Ambas")) {
            var option = document.createElement('option');
            option.value = "Ambas";
            option.textContent = "Ambas";
            tipoPersonaSelect.appendChild(option);
          }
        } else {
          Array.from(tipoPersonaSelect.options).forEach(option => {
            if (option.value === "Ambas") {
              tipoPersonaSelect.removeChild(option);
            }
          });
        }
      });
    }
  }
  



  addEventListener("direct-upload:initialize", event => {
    const { target, detail } = event
    const { id, file } = detail
    target.insertAdjacentHTML("beforebegin", `
      <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
        <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
        <span class="direct-upload__filename">${file.name}</span>
      </div>
    `)
  })

  addEventListener("direct-upload:start", event => {
    const { id } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.remove("direct-upload--pending")
  })

  addEventListener("direct-upload:progress", event => {
    const { id, progress } = event.detail
    const progressElement = document.getElementById(`direct-upload-progress-${id}`)
    progressElement.style.width = `${progress}%`
  })

  addEventListener("direct-upload:error", event => {
    event.preventDefault()
    const { id, error } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.add("direct-upload--error")
    element.setAttribute("title", error)
  })

  addEventListener("direct-upload:end", event => {
    const { id } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.add("direct-upload--complete")
  })






